export default function rechner2021(wages, people) {
    let start = 126000;
    let addon = 0;
    let index = -1;

    if (wages < 9999) {
        wages *= 100;
    }

    if (people === 1) {
        start = 173000;
    }
    if (people === 2) {
        start = 199000;
    }
    if (people === 3) {
        start = 225000;
    }
    if (people === 4) {
        start = 252000;
    }
    if (people === 5) {
        start = 278000;
    }

    if (wages > 384008) {
        addon = wages - 384008;
        wages = 384008;
    }

    while (wages >= start) {
        start += 1000;
        index++;
    }

    if (index === -1) {
        return 0;
    }

    let startAmount = 515;
    let indexAmount = 700;

    if (people === 1) {
        startAmount = 296;
        indexAmount = 500;
    }
    if (people === 2) {
        startAmount = 131;
        indexAmount = 400;
    }
    if (people === 3) {
        startAmount = 19;
        indexAmount = 300;
    }
    if (people === 4) {
        startAmount = 159;
        indexAmount = 200;
    }
    if (people === 5) {
        startAmount = 53;
        indexAmount = 100;
    }

    return startAmount + (index * indexAmount) + addon;
}
