/**
 * @param {NodeList} elements
 * @param {string} backgroundColor
 */
function setBackgroundColor(elements, backgroundColor) {
  elements.forEach(element => {
    element.style.backgroundColor = backgroundColor;
  });
}

/**
 * @param {NodeList} elements
 * @param {string} textColor
 */
function setTextColor(elements, textColor) {
  elements.forEach(element => {
    element.style.color = textColor;
  });
}

/**
 * @param {URLSearchParams} searchParams
 */
function applyCustomization(searchParams) {
  if (searchParams.has('backgroundColor')) {
    setBackgroundColor(document.querySelectorAll("body"), searchParams.get('backgroundColor'));
  }

  if (searchParams.has('textColor')) {
    setTextColor(document.querySelectorAll(".title, .label, .button:not(.is-static), .help, .webmaster, .privacy"), searchParams.get('textColor'));
  }

  if (searchParams.has('buttonBackgroundColor')) {
    setBackgroundColor(document.querySelectorAll(".button:not(.is-static)"), searchParams.get('buttonBackgroundColor'));
  }

  if (searchParams.has('buttonTextColor')) {
    setTextColor(document.querySelectorAll(".button:not(.is-static)"), searchParams.get('buttonTextColor'));
  }
}

export { setBackgroundColor, setTextColor, applyCustomization };
