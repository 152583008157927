import iframeResizer from "iframe-resizer/js/iframeResizer.contentWindow";
import rechner2021 from "./src/rechner2021";
import rechner2022 from "./src/rechner2022";
import rechner2023 from "./src/rechner2023";
import {applyCustomization} from "./src/customization";

const searchParams = new URLSearchParams(decodeURI(location.search));
const result = document.querySelector("div.result");

let year;

if (searchParams.has('year')) {
  year = searchParams.get('year')
} else {
  year = (new Date() < new Date(2024, 6, 1, 0, 0, 0, 0)) ? '2023' : '2024';
}

document.querySelector("form").addEventListener("submit", e => {
  e.preventDefault();

  const wages = document.querySelector('input[name="wages"]').value;
  const people = document.querySelector('select[name="people"]').selectedIndex;

  document.querySelector(".text-wages").innerText = Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR"
  }).format(wages);

  let peopleText = people + " unterhalts&shy;berechtigten Personen";

  if (people < 2) {
    peopleText = (people === 0 ? "keiner" : "einer") + " unterhalts&shy;berechtigten Person";
  }

  document.querySelector(".text-people").innerHTML = peopleText;

  let x;

  if (year === '2021') {
    console.debug('use rules for 2021')
    x = rechner2021(wages * 100, people)
  } else if (year === '2022') {
    console.debug('use rules for 2022')
    x = rechner2022(wages * 100, people)
  } else if (year === '2023') {
    console.debug('use rules for 2023')
    x = rechner2023(wages * 100, people)
  } else {
    console.error('unknown calculator')
    return
  }

  document.querySelector(".text-seizure").innerText = Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(x / 100);
  result.classList.remove("is-hidden");
});

applyCustomization(searchParams); // apply customizations

iframeResizer; // reference iframe resizer once to prevent tree shaking.
