export const PROTECTED_EARNINGS_RATE = 429881;

export default function rechner2023(wages, people) {
    let start = 141000;
    let addon = 0;
    let index = -1;

    if (wages < 9999) {
        wages *= 100;
    }

    if (people === 1) {
        start = 194000;
    }
    if (people === 2) {
        start = 223000;
    }
    if (people === 3) {
        start = 252000;
    }
    if (people === 4) {
        start = 282000;
    }
    if (people === 5) {
        start = 311000;
    }

    if (wages > PROTECTED_EARNINGS_RATE) {
        addon = wages - PROTECTED_EARNINGS_RATE;
        wages = PROTECTED_EARNINGS_RATE;
    }

    while (wages >= start) {
        start += 1000;
        index++;
    }

    if (index === -1) {
        return 0;
    }

    let startAmount = 540;
    let indexAmount = 700;

    if (people === 1) {
        startAmount = 498;
        indexAmount = 500;
    }
    if (people === 2) {
        startAmount = 238;
        indexAmount = 400;
    }
    if (people === 3) {
        startAmount = 58;
        indexAmount = 300;
    }
    if (people === 4) {
        startAmount = 158;
        indexAmount = 200;
    }
    if (people === 5) {
        startAmount = 39;
        indexAmount = 100;
    }

    return startAmount + (index * indexAmount) + addon;
}
